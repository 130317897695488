import React, { useContext, useMemo, useState, useEffect } from 'react';
import { css } from 'emotion';
import ReportDetailHeader from './components/ReportDetailHeader';
import theme from 'config/theme';
import ReportItem from './components/ReportItem';
import {
    Button,
    Card,
    Divider,
    Dropdown,
    message,
    Skeleton,
    Tooltip,
    Transfer,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Sortable } from 'devextreme-react';
import ReportInstructionsModal from './components/ReportInstructionsModal';
import {
    SavedFiltersProvider,
    EntityAttributesProvider,
    EntityAttributesContextType,
    EntityAttributesContext,
} from 'contexts';
import { funnelEntities } from 'components/app/global-filter-drawer/globalFilterUtils';
import {
    CreateReportWidgetParams,
    Entity,
    EntityReport,
    LocalReportItem,
    LocalReportSettings,
    ReportWidget,
    Settings,
} from 'waypoint-types';
import {
    useFilterEditorOperations,
    useGetReportById,
    useGetReportSettings,
} from 'waypoint-hooks';
import { toArray } from 'lodash';
import ResponsiveBox, {
    Col,
    Item,
    Row,
    Location,
} from 'devextreme-react/responsive-box';
import { DragStartEvent, ReorderEvent } from 'devextreme/ui/sortable';
import {
    ALL_AROUND_HEIGHT,
    TabKeys,
    WidgetTypes,
    WorkflowTabKeys,
} from './constants';
import { SelectedAttributes } from 'components/app/global-filter-drawer/types/globalFilterTypes';
import { EntityAttribute } from 'components/analytics/portfolioSegmentation/propertyAttributes/utils';
import { Link, useHistory } from 'react-router-dom';
import FilterEditorForm from 'components/app/global-filter-drawer/fitler-editor/FilterEditorForm';
import { getWidgetSettingsBuilder } from 'components/reports/report-widget/settings-builder/utils';
import {
    createReport,
    createReportSettings,
    updateEntityReports,
    updateReport as updateReportRequest,
    updateReportStatus,
    updateReportSettings,
} from 'waypoint-requests';
import { format } from 'date-fns';
import {
    decorateReportWidgets,
    REPORT_DRAFT,
    checkIfValidUUID,
    reportHasCorrectSettings,
    componentVerifications,
    getDefaultSettings,
    ReportDetailCardStyle,
} from './ReportUtils';
import { numericalSort, stringSort } from 'utils/tables/sorters';
import moment from 'moment';
import ReportSettings from './components/report-settings/ReportSettings';
import { WidgetType } from 'waypoint-types/report/types';
import { RootStateOrAny, connect } from 'react-redux';
import { useReportWorkflowContainerHeight } from 'waypoint-hooks/useReportWorkflowContainerHeight';

interface EntityForTransfer extends Entity {
    key: string;
    title: string;
}

const rootPageStyle = css`
    height: 100%;
    overflow-y: scroll;
    display: flex;
    background-color: ${theme.colors.white};
    flex-grow: 1;
    flex-direction: column;
`;

const reportDetailContainerStyle = css`
    background-color: #fff;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const reportDetailTable = css`
    background-color: ${theme.colors.grays.background};
    padding: 20px;
    flex: 1;
`;

const titleHeader = css`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    padding: 0 8px;
    color: ${theme.colors.black};
`;

const transferStyle = () => css`
    width: 100%;
    height: 100%;
    .ant-transfer-list {
        flex: 1;
        height: 100%;
        max-height: ${window.innerHeight - 440}px;
    }
    .ant-transfer-list-header > *,
    .ant-transfer-list-content-item {
        input[type='checkbox'] {
            position: absolute;
            margin-right: unset;
            margin-left: unset;
            cursor: pointer;
            width: auto;
    }
`;

const cardBodyInnerStyle = () => css`
    border: 1px solid #f0f0f0;
    .ant-card-body {
        height: ${window.innerHeight - 340}px;
        overflow: scroll;
    }
    .ant-card-actions {
        justify-content: space-around;
        li {
            span {
                max-width: fit-content;
                margin: 0 auto;
            }
        }
    }
`;

interface ReportDetailProps {
    reportId: string;
    workflowReportId?: string;
    isWorkflowSteps?: boolean;
    setWorkflowActiveTabKey?: (value: number) => void;
    setWorkflowCompleteSteps?: (steps: number[]) => void;
    renderWorkflowStepsNavigation?: (
        onNext: () => void,
        onSave: () => void,
        isNextDisabled: boolean
    ) => JSX.Element;
}

const ReportDetail = ({
    reportId,
    workflowReportId,
    isWorkflowSteps,
    setWorkflowActiveTabKey,
    setWorkflowCompleteSteps,
    renderWorkflowStepsNavigation,
}: ReportDetailProps) => {
    const [reportItems, setReportItems] = useState<LocalReportItem[]>([]);
    const [selectedRow, setSelectedRow] = useState<LocalReportItem | null>(
        null
    );
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedPropertiesKeys, setSelectedPropertiesKeys] = useState<
        string[]
    >([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [initialData, setInitialData] = useState<EntityForTransfer[]>([]);
    const [filters, setFilters] = useState<SelectedAttributes | null>(null);
    const [activeTabKey, setActiveTabKey] = useState<string>(
        TabKeys.ReportTemplate
    );
    const [isWidgetSettingsModalOpen, setIsWidgetSettingsModalOpen] =
        useState<boolean>(false);
    const [widgetType, setWidgetType] = useState<string | null>(null);
    const [reportName, setReportName] = useState<string>(
        `Unnamed Report ${format(new Date(), 'MM/dd/yyyy @ hh:mm aa')}`
    );
    const [dueDate, setDueDate] = useState<moment.Moment | null>(moment());
    const [status, setStatus] = useState<string>(REPORT_DRAFT);
    const [reportSettings, setReportSettings] = useState<LocalReportSettings>(
        {}
    );
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const workflowContainerHeight = useReportWorkflowContainerHeight();

    const {
        data: report,
        isLoading,
        mutate,
    } = useGetReportById(workflowReportId ?? reportId, isWorkflowSteps);

    const tabList = [
        {
            key: TabKeys.ReportTemplate,
            tab: 'Report Template',
        },

        {
            key: TabKeys.PropertyAssignments,
            tab: 'Property Assignments',
        },
        {
            key: TabKeys.ReportSettings,
            tab: 'Report Settings',
        },
    ];

    const handleOpenChange = (flag: boolean) => {
        setDropdownIsOpen(flag);
    };

    const handleSettingsProperties = (
        type: string,
        properties: Settings,
        shouldReplaceObject?: boolean
    ) => {
        setReportSettings(
            (prevSettings: {
                [type in WidgetType]: {
                    enabled: boolean;
                    settings: Settings;
                    id?: string;
                };
            }) => {
                const updatedSettings = shouldReplaceObject
                    ? {
                          ...properties,
                      }
                    : {
                          ...prevSettings[type]?.settings,
                          ...properties,
                      };

                const updatedType = {
                    ...prevSettings[type],
                    settings: updatedSettings,
                };
                return { ...prevSettings, [type]: updatedType };
            }
        );
    };

    const setEnabledSetting = (type: string, enabled: boolean) => {
        setReportSettings((prevSettings: LocalReportSettings) => {
            const updatedType = {
                ...prevSettings[type],
                enabled,
            };
            return { ...prevSettings, [type]: updatedType };
        });
    };

    useEffect(() => {
        if (!report) {
            return;
        }

        const reportItem = report.reportWidgets
            .map((item: ReportWidget) => decorateReportWidgets(item))
            .sort((a, b) => numericalSort(a.sort_index, b.sort_index));

        setReportItems(reportItem);

        const selectedEntityCodes = report.entityReports?.length
            ? report.entityReports?.map(
                  (item: EntityReport) => item.entity_code
              )
            : [];
        setSelectedPropertiesKeys(selectedEntityCodes);

        setStatus(report.state);
        setReportName(report.name);
        setDueDate(report.due_date ? moment(report.due_date) : null);

        const newSettings: LocalReportSettings = {};
        report.reportSettings.forEach((setting) => {
            newSettings[setting.settings_type] = {
                enabled: setting.enabled,
                settings: setting.settings,
            };

            if (setting.id) {
                newSettings[setting.settings_type].id = setting.id;
            }
        });

        setReportSettings(newSettings);
    }, [report]);

    const toggleIsWidgetSettingsModalOpen = (
        selectedReportItem: LocalReportItem
    ) => {
        if (selectedReportItem) {
            setWidgetType(selectedReportItem.component);
            setSelectedRow(selectedReportItem);
            setIsWidgetSettingsModalOpen(!isWidgetSettingsModalOpen);
        }
    };

    const updateAllEntityReportsDueDates = async () => {
        try {
            const entityReportsWithDueDateUpdated = report?.entityReports
                ?.length
                ? report?.entityReports.map((entityReport) => {
                      const shouldUpdateEntityReportDueDate =
                          moment(entityReport.due_date).isAfter(dueDate) ||
                          !entityReport.due_date;

                      const dueDateToUpdate = shouldUpdateEntityReportDueDate
                          ? moment(dueDate)
                          : moment(entityReport.due_date);

                      return {
                          ...entityReport,
                          selectedReports: [entityReport.id],
                          dueDate: dueDateToUpdate,
                      };
                  })
                : [];

            if (entityReportsWithDueDateUpdated) {
                const results = await Promise.allSettled(
                    entityReportsWithDueDateUpdated.map(updateEntityReports)
                );

                if (results.some((result) => result.status === 'rejected')) {
                    message.error('Failed to update entity reports');
                }
            }

            if (
                report?.entityReports?.some((entityReport) =>
                    moment(entityReport.due_date).isAfter(dueDate)
                )
            ) {
                message.success(
                    'All Entity Reports due after this date will be updated with this newly selected date'
                );
            }
        } catch (error) {
            message.error('Failed to update entity reports');
        }
    };

    const onSaveWidgetSettings = (settings: { [key: string]: any }) => {
        const updatedReportItem = {
            ...selectedRow,
            settings_inputs: settings,
        };
        onUpdateReportItem(updatedReportItem);
        setIsWidgetSettingsModalOpen(false);
        setSelectedRow(null);
    };

    const ReportWidget = useMemo(() => {
        return widgetType ? getWidgetSettingsBuilder(widgetType) : null;
    }, [widgetType]);

    const entityContext = useContext<EntityAttributesContextType | null>(
        EntityAttributesContext
    );

    const history = useHistory();

    const {
        attributeFilters,
        setAttributeFilters,
        selectedFilterId,
        savedFilterPartial,
        saveAsVisible,
        setSaveAsVisible,
        controlsDisabled,
        handleClearFilter,
        setSavedFilterPartial,
        setSelectedFilterId,
        handleDelete,
        handleSave,
        savedFilterContext,
        hasAtLeastOneValidFilter,
    } = useFilterEditorOperations({});

    const { data } = entityContext || {};

    const { data: reportSetttingsData } = useGetReportSettings();

    useMemo(() => {
        if (!data) {
            return [];
        }

        const entities =
            data?.entities
                .map((entity) => ({
                    ...entity,
                    key: entity?.entity_code?.toString() ?? '',
                    title: entity?.name?.toString() ?? 'N/A',
                }))
                .filter((entity) => entity.key !== '' || entity.title !== '')
                .sort((a, b) => stringSort(b.title, a.title)) ?? [];

        setInitialData(entities);
    }, [data]);

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const sortTargetKeys = (nextTargetKeys: string[]) => {
        if (!initialData.length) {
            return nextTargetKeys;
        }
        const targetEntities = initialData.filter((d) =>
            nextTargetKeys.includes(d.entity_code as string)
        );
        return targetEntities.map((entity) => entity.entity_code as string);
    };

    const onChange = (nextTargetKeys: string[]) => {
        setSelectedPropertiesKeys(sortTargetKeys(nextTargetKeys));
    };

    const allReportItemsHaveCorrectSettings = useMemo(
        () => (reportItems: LocalReportItem[]) => {
            return reportItems
                .filter((reportItem) =>
                    Object.keys(componentVerifications).includes(
                        reportItem.component
                    )
                )
                .every(reportHasCorrectSettings);
        },
        [reportItems, componentVerifications]
    );

    const onSelectChange = (
        sourceSelectedKeys: string[],
        targetSelectedKeys: string[]
    ) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const showModal = (reportItem: LocalReportItem) => {
        setSelectedRow(reportItem);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setSelectedRow(null);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setSelectedRow(null);
        setIsModalOpen(false);
    };

    const addNewReportItem = () => {
        setReportItems([
            ...reportItems,
            {
                name: 'New Section',
                instructionsEnabled: false,
                narrativeEnabled: false,
                narrative: 'above',
                instructions: '',
                component: '',
                settings_inputs: null,
                paper_size: reportSetttingsData?.paperSize[0].value ?? 'letter',
                orientation:
                    reportSetttingsData?.paperOrientation[1].value ??
                    'landscape',
                sort_index: reportItems.length,
                page_break: false,
            },
        ]);
    };

    const onUpdateReportItem = (reportItem: any) => {
        const updatedReportItems = reportItems.map((item) => {
            if (item.sort_index === reportItem.sort_index) {
                return reportItem;
            }
            return item;
        });
        setReportItems(updatedReportItems);
    };

    const buildCreateReportPayload = () => {
        const createReportPayload = {
            name: reportName ?? '',
            entity_codes: selectedPropertiesKeys,
            widgets: [] as CreateReportWidgetParams[],
            state: status ?? REPORT_DRAFT,
            due_date: dueDate?.toISOString() ?? report?.due_date ?? '',
        };

        const getNarrativePosition = (reportItem: LocalReportItem) => {
            if (reportItem.component === 'narrative') {
                return 'above';
            }
            return reportItem.narrativeEnabled ? reportItem.narrative : null;
        };

        const reportWidgets = reportItems.map((reportItem, index) => {
            return {
                id: reportItem.id,
                name: reportItem.name ?? '',
                widget_type: reportItem.component ?? '',
                settings_inputs: reportItem.settings_inputs ?? {},
                instructions: !reportItem.instructionsEnabled
                    ? ''
                    : reportItem.instructions,
                narrative_position: getNarrativePosition(reportItem),
                sort_index: index,
                paper_size: reportItem.paper_size,
                orientation: reportItem.orientation,
                page_break: reportItem.page_break,
            };
        });
        createReportPayload.widgets = reportWidgets;
        return createReportPayload;
    };

    const saveReport = async () => {
        try {
            const newReportId = await createReport(buildCreateReportPayload());
            message.success('Report created successfully');
            return newReportId;
        } catch (e) {
            message.error('Failed to create report');
        }
    };

    const updateReport = async () => {
        try {
            await updateReportRequest({
                id: workflowReportId ?? reportId,
                ...buildCreateReportPayload(),
            });
            if (report?.due_date !== dueDate) {
                await updateAllEntityReportsDueDates();
            }
            message.success('Report updated successfully');
        } catch (e) {
            message.error('Failed to update report');
        }
    };

    const onStatusChange = async (status: string) => {
        setStatus(status);

        if (checkIfValidUUID(reportId)) {
            await updateReportStatus({
                id: workflowReportId ?? reportId,
                state: status,
            });
        }
    };

    const handleSettings = async (
        reportId: string,
        existingSettings: {
            settings_type: string;
            enabled: boolean;
            settings: Settings;
            id?: string;
        }[]
    ) => {
        const existingSettingsMap =
            existingSettings.reduce<LocalReportSettings>((map, setting) => {
                map[setting.settings_type] = setting;
                return map;
            }, {});

        const settings: LocalReportSettings = {};

        Object.values(WidgetTypes).forEach((widgetType) => {
            const {
                enabled,
                id,
                settings: existingSettings,
            } = existingSettingsMap[widgetType] ?? {};

            settings[widgetType] = {
                enabled:
                    widgetType === WidgetTypes.Approvals || (enabled ?? false),
                settings: existingSettings
                    ? { ...existingSettings }
                    : getDefaultSettings()[
                          widgetType as keyof typeof getDefaultSettings
                      ],
                id,
            };
        });

        const settingsTypes = Object.keys(settings);

        const promises = settingsTypes.map(async (settingsType) => {
            const setting = settings[settingsType];

            if (setting?.id) {
                return await updateReportSettings({
                    id: setting.id,
                    reportId: workflowReportId ?? reportId,
                    settings: setting.settings,
                    settingsType: settingsType,
                    enabled: setting.enabled ?? false,
                });
            }

            if (!setting?.id && setting?.enabled) {
                return await createReportSettings([
                    {
                        reportId: workflowReportId ?? reportId,
                        settings: setting.settings,
                        settingsType: settingsType,
                        enabled: setting.enabled ?? false,
                    },
                ]);
            }
        });

        return await Promise.all(promises);
    };

    const onSaveReport = async (
        redirectOnUpdate = true,
        changeWorkflowStep = true
    ) => {
        setIsSaving(true);
        const settingsArray = Object.entries(reportSettings).map(
            ([settings_type, { enabled, settings, id }]) => ({
                settings_type,
                enabled,
                settings,
                ...(id && { id }),
            })
        );

        if (report) {
            await updateReport();
            await handleSettings(report.id, settingsArray);
            if (redirectOnUpdate) {
                history.push(`/report/manager/${report.id}`);
            }

            setWorkflowActiveTabKey &&
                changeWorkflowStep &&
                setWorkflowActiveTabKey(WorkflowTabKeys.Assign);
            setWorkflowCompleteSteps &&
                setWorkflowCompleteSteps([
                    WorkflowTabKeys.Details,
                    WorkflowTabKeys.Setup,
                    WorkflowTabKeys.Assign,
                ]);

            setIsSaving(false);
            mutate();
            return;
        }

        const reportIdParam = await saveReport();

        await handleSettings(reportIdParam ?? '', settingsArray);
        history.push(`/report/manager/${reportIdParam}`);
        setIsSaving(false);
    };

    const onDeleteReportItem = (sortIndex: number) => {
        setReportItems(
            reportItems
                .filter((item) => item.sort_index !== sortIndex)
                .map((item, index) => {
                    return {
                        ...item,
                        sort_index: index,
                    };
                })
        );
    };

    const onDragStart = (e: DragStartEvent) => {
        e.itemData = reportItems[e.fromIndex];
    };

    const onReorder = (e: ReorderEvent) => {
        let items = reportItems;

        items = [
            ...items.slice(0, e.fromIndex),
            ...items.slice(e.fromIndex + 1),
        ];
        items = [
            ...items.slice(0, e.toIndex),
            e.itemData,
            ...items.slice(e.toIndex),
        ];
        setReportItems(items);
    };

    const filterEntities = ({
        attributes,
        allProperties,
        filtersApplied,
    }: {
        attributes: EntityAttribute[];
        allProperties: EntityForTransfer[];
        filtersApplied: SelectedAttributes;
    }): EntityForTransfer[] => {
        if (!toArray(filtersApplied).length) {
            return allProperties;
        }

        const filteredEntities = funnelEntities(
            attributes,
            allProperties,
            filtersApplied
        );

        return filteredEntities as EntityForTransfer[];
    };

    const onFiltersUpdate = (filters: SelectedAttributes) => {
        setFilters(filters);
    };

    const getReportWidgetEntityCodes = () => {
        return selectedKeys.length
            ? selectedKeys
            : initialData.map((d) => d.entity_code.toString());
    };

    const renderSaveButton = () => {
        if (!reportItems.length || !selectedPropertiesKeys.length) {
            return (
                <Tooltip title="Select at least one section and one entity">
                    <Button style={{ width: 200 }} disabled type="primary">
                        Save
                    </Button>
                </Tooltip>
            );
        }

        if (reportItems.some((item) => !item.component.length)) {
            return (
                <Tooltip title="All sections need to have at least one component selected">
                    <Button style={{ width: 200 }} disabled type="primary">
                        Save
                    </Button>
                </Tooltip>
            );
        }

        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            key: '1',
                            label: (
                                <Button
                                    type="link"
                                    onClick={() => onSaveReport(true)}
                                >
                                    Save and Exit
                                </Button>
                            ),
                        },
                        {
                            key: '2',
                            label: (
                                <Button
                                    type="link"
                                    onClick={() => onSaveReport(false)}
                                >
                                    Save
                                </Button>
                            ),
                        },
                    ],
                }}
                trigger={['click']}
                placement="bottomLeft"
                open={dropdownIsOpen}
                onOpenChange={handleOpenChange}
            >
                <Button
                    data-test="save-dropdown-button"
                    type="primary"
                    style={{ width: 200 }}
                    disabled={
                        !allReportItemsHaveCorrectSettings(reportItems) ||
                        isSaving
                    }
                >
                    Save
                </Button>
            </Dropdown>
        );
    };

    if (reportId !== 'create' && isLoading) {
        return <Skeleton />;
    }

    const showWorkflowStepsNavigation = () => {
        if (!isWorkflowSteps) {
            return null;
        }

        const isNextButtonDisabled =
            !allReportItemsHaveCorrectSettings(reportItems) ||
            isSaving ||
            !reportItems.length ||
            !selectedPropertiesKeys.length ||
            reportItems.some((item) => !item.component.length);

        return (
            renderWorkflowStepsNavigation &&
            renderWorkflowStepsNavigation(
                async () => {
                    await onSaveReport(false, true);
                },
                async () => {
                    await onSaveReport(false, false);
                },
                isNextButtonDisabled
            )
        );
    };

    const contentList: Record<string, React.ReactNode> = {
        reportTemplate: (
            <div data-test="report-grid" className={reportDetailTable}>
                <ResponsiveBox>
                    <Row />
                    <Col ratio={1.75} />
                    <Col ratio={2} />
                    <Col ratio={0.3} />
                    <Col ratio={0.75} />
                    <Col ratio={2} />
                    <Col ratio={1} />
                    <Col ratio={0.65} />
                    <Col ratio={0.7} />
                    <Col ratio={0.2} />

                    <Item>
                        <Location row={0} col={0} />
                        <div className={titleHeader}>Component</div>
                    </Item>
                    <Item>
                        <Location row={0} col={1} />
                        <div className={titleHeader}>Name</div>
                    </Item>
                    <Item>
                        <Location row={0} col={2} />
                        <div className={titleHeader}></div>
                    </Item>
                    <Item>
                        <Location row={0} col={3} />
                        <div className={titleHeader}>Narrative</div>
                    </Item>
                    <Item>
                        <Location row={0} col={4} />
                        <div className={titleHeader}>Instructions</div>
                    </Item>
                    <Item>
                        <Location row={0} col={5} />
                        <div className={titleHeader}>Orientation</div>
                    </Item>
                    <Item>
                        <Location row={0} col={6} />
                        <div className={titleHeader}>Size</div>
                    </Item>
                    <Item>
                        <Location row={0} col={7} />
                        <div className={titleHeader}>Page Break</div>
                    </Item>
                    <Item>
                        <Location row={0} col={8} />
                        <div className={titleHeader}></div>
                    </Item>
                </ResponsiveBox>
                <Sortable
                    onDragStart={onDragStart}
                    onReorder={onReorder}
                    scrollSensitivity={50}
                    scrollSpeed={40}
                    style={{
                        maxHeight: window.innerHeight - ALL_AROUND_HEIGHT,
                        overflow: 'auto',
                    }}
                >
                    {reportItems.map((reportItem) => (
                        <ReportItem
                            key={reportItem.id}
                            reportItem={reportItem}
                            onDeleteReportItem={onDeleteReportItem}
                            onUpdateReportItem={onUpdateReportItem}
                            addNewReportItem={addNewReportItem}
                            showModal={showModal}
                            toggleIsWidgetSettingsModalOpen={
                                toggleIsWidgetSettingsModalOpen
                            }
                        />
                    ))}
                </Sortable>
                <Divider />
                <div
                    data-test="report-item-add-section-button"
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Button
                        style={{ maxWidth: 200, margin: '0 auto' }}
                        type="primary"
                        block
                        icon={<PlusOutlined />}
                        onClick={() => addNewReportItem()}
                    >
                        Add Section
                    </Button>
                </div>
                {ReportWidget && widgetType && (
                    <ReportWidget
                        key={`${selectedRow?.sort_index}_${widgetType}_${isWidgetSettingsModalOpen}`}
                        entityCodes={getReportWidgetEntityCodes()}
                        isModalOpen={isWidgetSettingsModalOpen}
                        setIsModalOpen={setIsWidgetSettingsModalOpen}
                        onSaveWidgetSettings={onSaveWidgetSettings}
                        widgetType={widgetType}
                        currentSettings={selectedRow?.settings_inputs ?? null}
                    />
                )}
            </div>
        ),
        propertyAssignments: (
            <SavedFiltersProvider>
                <EntityAttributesProvider>
                    <ResponsiveBox>
                        <Row />
                        <Col ratio={0.9} />
                        <Col ratio={2} />
                        <Item>
                            <Location row={0} col={0} />
                            <div
                                style={{
                                    paddingRight: 24,
                                    borderRight:
                                        '1px solid rgba(0, 0, 0, 0.06)',
                                    marginTop: -16,
                                    marginBottom: -16,
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    height: '100%',
                                    overflow: 'auto',
                                    maxHeight: 880,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <FilterEditorForm
                                    handleClearFilter={handleClearFilter}
                                    controlsDisabled={controlsDisabled}
                                    handleDelete={handleDelete}
                                    attributeFilters={attributeFilters}
                                    setAttributeFilters={setAttributeFilters}
                                    saveAsVisible={saveAsVisible}
                                    savedFilterPartial={savedFilterPartial}
                                    setSaveAsVisible={setSaveAsVisible}
                                    handleSave={handleSave}
                                    selectedFilterId={selectedFilterId}
                                    savedFilterContext={savedFilterContext}
                                    hasAtLeastOneValidFilter={
                                        hasAtLeastOneValidFilter
                                    }
                                    setSavedFilterPartial={
                                        setSavedFilterPartial
                                    }
                                    setSelectedFilterId={setSelectedFilterId}
                                    onFiltersUpdate={onFiltersUpdate}
                                    canDeleteFilters={false}
                                    styles={{ width: '100%' }}
                                />
                            </div>
                        </Item>
                        <Item>
                            <Location row={0} col={1} />
                            <div
                                style={{
                                    paddingLeft: 24,
                                    height: '100%',
                                }}
                            >
                                <Transfer
                                    data-test="report-item-property-assignments-transfer"
                                    className={transferStyle()}
                                    dataSource={filterEntities({
                                        attributes: data?.attributes ?? [],
                                        allProperties: initialData,
                                        filtersApplied: filters ?? {},
                                    })}
                                    titles={[
                                        'Available Properties',
                                        'Assigned Properties',
                                    ]}
                                    targetKeys={sortTargetKeys(
                                        selectedPropertiesKeys
                                    )}
                                    selectedKeys={selectedKeys}
                                    onChange={onChange}
                                    onSelectChange={onSelectChange}
                                    render={(item: {
                                        title: string;
                                        key: string;
                                    }) => item.title}
                                    showSearch
                                    filterOption={(input, option) => {
                                        const optionToFilter =
                                            option?.title ?? '';
                                        return optionToFilter
                                            .toLowerCase()
                                            .includes(input.toLowerCase());
                                    }}
                                />
                            </div>
                        </Item>
                    </ResponsiveBox>
                </EntityAttributesProvider>
            </SavedFiltersProvider>
        ),
        reportSettings: (
            <ReportSettings
                report={report}
                settings={reportSettings}
                setSettings={handleSettingsProperties}
                setEnabledSetting={setEnabledSetting}
                onSaveSettings={onSaveReport}
                isWorkflowSteps={!!isWorkflowSteps}
            />
        ),
    };

    return (
        <div className={rootPageStyle}>
            <div
                className={reportDetailContainerStyle}
                style={{
                    margin: isWorkflowSteps ? '0 20px' : 'unset',
                    padding: !isWorkflowSteps ? '12px' : '0',
                }}
            >
                {!isWorkflowSteps ? (
                    <ReportDetailHeader
                        report={report}
                        reportName={reportName}
                        setReportName={setReportName}
                        onStatusChange={onStatusChange}
                        status={status}
                        dueDate={dueDate}
                        setDueDate={setDueDate}
                        isWorkflowSteps={!!isWorkflowSteps}
                    />
                ) : null}
                <ReportInstructionsModal
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    onUpdateReportItem={onUpdateReportItem}
                    reportItem={selectedRow}
                />
                <Card
                    className={
                        isWorkflowSteps
                            ? ReportDetailCardStyle(
                                  isWorkflowSteps
                                      ? workflowContainerHeight - 50
                                      : '100%'
                              )
                            : cardBodyInnerStyle()
                    }
                    style={{
                        marginTop: 6,
                        marginBottom: 50,
                    }}
                    tabList={tabList}
                    activeTabKey={activeTabKey}
                    onTabChange={(key) => {
                        onTabChange(key);
                    }}
                    data-test="report-detail-card"
                    actions={
                        !isWorkflowSteps
                            ? [
                                  <Link
                                      to={
                                          report
                                              ? `/report/manager/${report.id}`
                                              : '/reports/manager'
                                      }
                                  >
                                      <Button style={{ width: 200 }}>
                                          Cancel
                                      </Button>
                                  </Link>,
                                  renderSaveButton(),
                              ]
                            : []
                    }
                >
                    {contentList[activeTabKey]}
                </Card>
            </div>
            {showWorkflowStepsNavigation()}
        </div>
    );
};

const mapState = (s: RootStateOrAny) => {
    const {
        routing: {
            locationBeforeTransitions: { pathname },
        },
    } = s;
    const reportId = pathname.split('/').pop();

    return {
        reportId,
    };
};

export default connect(mapState)(ReportDetail);
